import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "timi-entry-top-actionbar" }
const _hoisted_2 = { class: "actionbar-content" }
const _hoisted_3 = { class: "timi-timeentryform-container" }
const _hoisted_4 = { class: "timi-timeentryform-content" }
const _hoisted_5 = { class: "tab-header-bar" }
const _hoisted_6 = { class: "tab-header-bar-actions" }
const _hoisted_7 = { class: "listitem__content" }
const _hoisted_8 = { class: "tab-header-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ListItem = _resolveComponent("ListItem")!
  const _component_List = _resolveComponent("List")!
  const _component_UiTab = _resolveComponent("UiTab")!
  const _component_UiTabs = _resolveComponent("UiTabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.activeTab == 1)
          ? (_openBlock(), _createBlock(_component_IconButton, {
              key: 0,
              class: "btn-back",
              icon: _ctx.store.getters.svgIcons.arrowbackward,
              label: _ctx.$t('button.back'),
              dense: true,
              onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSwitchTab(-1)))
            }, null, 8, ["icon", "label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_IconButton, {
          class: "btn-cancel",
          icon: _ctx.store.getters.svgIcons.close,
          label: _ctx.$t('button.cancel'),
          dense: true,
          onOnClick: _ctx.onCancel
        }, null, 8, ["icon", "label", "onOnClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_UiTabs, { hideTabsHead: true }, {
          default: _withCtx(() => [
            _createVNode(_component_UiTab, {
              class: _normalizeClass({'active': _ctx.activeTab == 0 })
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('project.selectYourProject')), 1),
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.sessionCompany?.settings?.defaultProject)
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          raised: true,
                          primary: true,
                          text: _ctx.$t('button.next'),
                          onOnClick: _ctx.onSetDefaultProject
                        }, null, 8, ["text", "onOnClick"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createVNode(_component_List, {
                  isEmpty: _ctx.filteredProjectList.length>0 ? false : true,
                  emptyStateText: _ctx.$t('emptyState.projects'),
                  raised: true
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProjectList, (project) => {
                      return (_openBlock(), _createBlock(_component_ListItem, {
                        key: project.uid,
                        onClick: ($event: any) => (_ctx.onSelectProject(project)),
                        interactive: true
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("h3", null, _toDisplayString(project.numberExternal ? project.numberExternal + ' ' : '') + _toDisplayString(project.numberInternal ? project.numberInternal + ' ' : '') + _toDisplayString(project.numberSerial ? project.numberSerial + ' ' : '') + _toDisplayString(project.title), 1),
                            _createElementVNode("p", null, _toDisplayString(project?.customer?.custName), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["isEmpty", "emptyStateText"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_UiTab, {
              class: _normalizeClass({'active': _ctx.activeTab == 1 })
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('work.selectYourWork')), 1)
                ]),
                _createVNode(_component_List, {
                  isEmpty: _ctx.filteredWorkList.length>0 ? false : true,
                  emptyStateText: _ctx.$t('emptyState.works'),
                  class: "timeentry-select-work"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredWorkList, (work) => {
                      return (_openBlock(), _createBlock(_component_ListItem, {
                        key: work.uid,
                        onClick: ($event: any) => (_ctx.onSelectWork(work)),
                        interactive: true
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("p", null, _toDisplayString(work.title), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["isEmpty", "emptyStateText"])
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}